var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4620fcd670f25c926f8dd71e15ea6a3cef72e212"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import {
  getSchibstedCookieConsentValue,
  getIsSourcepointClientActive,
} from '@qasa/app/src/vendor/schibsted-sourcepoint-client/schibsted-sourcepoint-client.web'

import { ENV } from './src/helpers/env'
import { ConsentCookie } from './src/helpers/cookie'

const NO_INTERNET_CONNECTION_ERRORS = [
  'Network request failed',
  'Failed to fetch',
  'NetworkError',
  'Network Error',
  'Misslyckades med fetch',
]

Sentry.init({
  dsn: ENV.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: ENV.BRAND_NAME,
  enabled: ENV.SENTRY_DISABLED !== 'true',
  sampleRate: 0.5,
  ignoreErrors: NO_INTERNET_CONNECTION_ERRORS,
  beforeSend: (event) => {
    let hasConsentedToAnalytics = false

    if (getIsSourcepointClientActive()) {
      hasConsentedToAnalytics = getSchibstedCookieConsentValue('CMP:analytics')
    } else {
      hasConsentedToAnalytics = Boolean(ConsentCookie.get().analytics)
    }

    if (!hasConsentedToAnalytics) {
      return null
    }

    return event
  },
})

// Temporary tag to identify errors from the new setup in Sentry
Sentry.setTag('Next.js', 'true')
